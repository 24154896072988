import React from "react";

export const ItemProgressDetail = ({ name, weight, height, condition }) => {
    return (
        <tr>
            <td>{name}</td>
            <td>{weight}</td>
            <td>{height}</td>
            <td>{condition}</td>
        </tr>
    );

}