import React from "react";
import { ItemProgressDetail } from "./ItemProgressDetail";

export const TableProgressDetails = ({ id }) => {
    return (
        <table className="table table-striped">
            <thead>
                <tr className="text-center">
                    <th scope="col">Fecha</th>
                    <th scope="col">Peso</th>
                    <th scope="col">Altura</th>
                    <th scope="col">Condición</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                <ItemProgressDetail
                    name={"enero 21"}
                    weight={"80 kg"}
                    height={"180 mts"}
                    condition={"bien"}
                />

                <ItemProgressDetail
                    name={"11/2020"}
                    weight={"90"}
                    height={"190 mts"}
                    condition={"bien"}
                />
            </tbody>

        </table>
    );
}