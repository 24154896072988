import React from "react";
import { Link } from "react-router-dom";

export const ItemCustomer = ({ id, name }) => {
    return (
        <tr>
            <td>{name}</td>
            <td className="text-center"><Link to={"/details-customer/" + id}><i className="bi bi-file-person-fill" /></Link></td>
        </tr>
    );

}