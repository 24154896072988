import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Index } from './views/Index';
import { Login } from './views/Login';
import Home from './views/Home';
import { CustomersHome } from './views/CustomersHome';
import { ProgressHome } from './views/ProgressHome';
import { MembershipsHome } from './views/MembershipsHome';
import { PostsHome } from './views/PostsHome';
import { CreateCustomer } from './views/CreateCustomer';
import { EditCustomerList } from './views/EditCustomerList';
import { EditCustomer } from './views/EditCustomer';
import { Response } from './views/Response';
import { DeleteCustomerList } from './views/DeleteCustomerList';
import { CustomerList } from './views/CustomerList';
import { CustomerDetails } from './views/CustomerDetails';
import { AddProgressList } from './views/AddProgressList';
import { AddProgress } from './views/AddProgress';
import { EditProgressList } from './views/EditProgressList';
import { EditProgress } from './views/EditProgress';
import { ProgressCustomerList } from './views/ProgressCustomerList';
import { ProgressDetails } from './views/ProgressDetails';
import { AddMembership } from './views/AddMembership';
import { MembersList } from './views/MembersList';
import { AppInfo } from './views/AppInfo';
import { Settings } from './views/Settings';
import { AcountSettings } from './views/AcountSettings';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/customers-home" element={<CustomersHome />} />
            <Route path="/progress-home" element={<ProgressHome />} />
            <Route path="/memberships-home" element={<MembershipsHome />} />
            <Route path="/posts-home" element={<PostsHome />} />
            <Route path="/create-customer" element={<CreateCustomer />} />
            <Route path="/edit-customer-list" element={<EditCustomerList />} />
            <Route path="/edit-customer/:id" element={<EditCustomer />} />
            <Route path="/Response/:status/:response?" element={<Response />} />
            <Route path="/delete-customer-list" element={<DeleteCustomerList />} />
            <Route path="/customer-list" element={<CustomerList />} />
            <Route path="/details-customer/:id" element={<CustomerDetails />} />
            <Route path="/add-progress-list" element={<AddProgressList />} />
            <Route path="/add-progress/:id" element={<AddProgress />} />
            <Route path="/edit-progress-list" element={<EditProgressList />} />
            <Route path="/edit-progress/:id" element={<EditProgress />} />
            <Route path="/progress-customer-list" element={<ProgressCustomerList />} />
            <Route path="/details-progress/:id" element={<ProgressDetails />} />
            <Route path="/add-membership" element={<AddMembership />} />
            <Route path="/members-list" element={<MembersList />} />
            <Route path="/app-info" element={<AppInfo />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/acount-settings/:id" element={<AcountSettings />} />
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
