import React from "react";
import { ItemEditProgress } from "./ItemEditProgress";

export const TableEditProgress = ({ }) => {
    return (
        <table className="table table-striped">
            <thead>
                <tr className="text-center">
                    <th scope="col">Nombre</th>
                    <th scope="col">fecha</th>
                    <th scope="col-1"><i class="bi bi-clipboard2-data"></i></th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                <ItemEditProgress
                    id={1}
                    name={"nombre"}
                    date={"11/11"}
                />

                <ItemEditProgress
                    id={2}
                    name={"nombre2"}
                    date={"12/12"}
                />
            </tbody>

        </table>
    );
}