import React from "react";
import { ItemDeleteCustomer } from "./ItemDeleteCustomer";

export const TableDeleteCustomer = ({ onClick }) => {
    return (
        <table className="table table-striped">
            <thead>
                <tr className="text-center">
                    <th scope="col">Nombre</th>
                    <th scope="col-1"><i className="bi bi-x-circle"></i></th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                <ItemDeleteCustomer
                    onClick={onClick}
                    id={1}
                    name={"nombre"}
                    isActive={true}
                />

                <ItemDeleteCustomer
                    onClick={onClick}
                    id={2}
                    name={"nombre2"}
                    isActive={false}
                />
            </tbody>

        </table>
    );
}