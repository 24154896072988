import React from "react";
import { Link } from "react-router-dom";

export const ItemDeleteCustomer = ({ onClick, id, name, isActive }) => {
    if(isActive){
        return (
            <tr>
                <td>{name}</td>
                <td className="text-center" onClick={() => onClick(true, id)}><i className="bi bi-x-circle-fill text-danger"></i></td>
            </tr>
        );
    }else{
        return (
            <tr>
                <td>{name}</td>
                <td className="text-center" onClick={() => onClick(false, id)}><i className="bi bi-check-circle-fill text-success"></i></td>
            </tr>
        );
    }
}