import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReturnButton } from "../components/customer/ReturnButton";
import { InputDate } from "../components/membership/InputDate";
import { SubmitAddMembership } from "../components/membership/SubmitAddMembership";
import { InputNum } from "../components/membership/InputNum";
import { InputSelect } from "../components/membership/InputSelect";

export function AddMembership() {
    useEffect(() => {
        document.title = "Academia Balam Boox - Agregar avance";
    }, []);

    const [idCustomer, setIdCustomer] = useState("");
    const [startDate, setStartDate] = useState(obtenerFechaActual());
    const [endDate, setEndDate] = useState("");
    const [price, setPrice] = useState("");

    function obtenerFechaActual() {
        const hoy = new Date();
        const año = hoy.getFullYear();
        const mes = (hoy.getMonth() + 1).toString().padStart(2, '0');
        const dia = hoy.getDate().toString().padStart(2, '0');
        return `${año}-${mes}-${dia}`;
    }

    const navigate = useNavigate();

    async function submit(event) {
        event.preventDefault();

        navigate("/Response/200/si jala x3");
    }

    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-3">
                    <ReturnButton
                        link={"/memberships-home"}
                        icon={(
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        )}
                    />
                </div>
                <div className="col" />
            </div>

            <div className="card text-bg-dark">
                <div className="card-body text-center">
                    <h3 className="card-title">Renovar membresía <i class="bi bi-person-vcard"></i></h3>
                    <form action="#" className="login-form" onSubmit={submit}>
                    <div className="row mb-3">
                            <InputSelect
                                value={idCustomer}
                                onChange={(e) => { setIdCustomer(e.target.value); }}
                                title={"Alumno"}
                                idInput={"inputIdCustomer"}
                            />
                        </div>
                        
                        <div className="row mb-3">
                            <InputDate
                                value={startDate}
                                onChange={(e) => { setStartDate(e.target.value); }}
                                title={"Fecha de inicio"}
                                idInput={"inputStartDate"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputDate
                                value={endDate}
                                onChange={(e) => { setEndDate(e.target.value); }}
                                title={"Fecha final"}
                                idInput={"inputEndDate"}
                            />
                        </div>

                        <div className="row mb-4">
                            <InputNum
                                value={price}
                                onChange={(e) => { setPrice(e.target.value); }}
                                title={"Costo"}
                                idInput={"inputPrice"}
                            />
                        </div>

                        <div className="row">
                            <SubmitAddMembership
                                title={"Agregar"}
                                idButton={"SubmitButton"}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}