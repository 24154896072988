import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';

export default function withAuth(Component) {
  return function WithAuth(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
  
    const fetchMe = async () => {
      try {
        const { data } = await api.get('/me');
        if (!data) {
          localStorage.removeItem('token');
          navigate('/login');
        }

        setIsAuthenticated(true);
      } catch (error) {
        alert('ocurrió un error, intente de nuevo');
        console.log(error);
        navigate('/');
      }
    };
  
    useEffect(() => {
      fetchMe();
    }, []);

    if (!isAuthenticated) {
      return <div>Loading...</div>;
    }

    return <Component {...props} />;
  }
}
