import React from "react";
import { Link } from "react-router-dom";

export const ButtonLink = ({ link, title, icon }) => {
    return (
        <Link to={link} className="btn btn-outline-light" id="ButtonLogin">
            {title}
            <br />
            {icon}
        </Link>
    );
}