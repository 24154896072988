import React from "react";
import { OkResponse } from "./OkResponse";
import { FailResponse } from "./FailResponse";

export const SetResponse = ({ status, response }) => {

    if (status === "200") {
        return (
            <OkResponse
                response={response}
            />
        );
    }
    else {
        return (
            <FailResponse
                response={response}
            />
        );
    }
}