import { React, useEffect } from "react";
import "../styles/index.css";
import Aos from "aos";
import { IndexHeader } from "../components/IndexHeader";
import { Banner } from "../components/index/Banner";
import { CardText } from "../components/index/CardText";
import { CardImage } from "../components/index/CardImage";
import logo from "../resources/logo.jpg"
import { CardIcon } from "../components/index/CardIcon";
import { Footer } from "../components/Footer";


export function Index() {
    useEffect(() => {
        document.title = "Academia Balam Boox";
        Aos.init();
    }, []);

    return (
        <>
        <section className="d-flex flex-column min-vh-100 bgDeg" style={{ marginTop: "3.5em" }}>
            <IndexHeader />
            <Banner />

            <div className="container mt-5 mb-5">
                <div className="row pb-5">
                    <CardText
                        title={"Bienvenido a Balam."}
                        text1={"Aquí, no solo entrenamos cuerpos, forjamos espíritus. Balam, que significa 'jaguar' en la ancestral lengua maya, simboliza nuestra filosofía: fuerza, agilidad y astucia."}
                         text2={"En Balam, no solo te convertirás en un boxeador hábil, sino en un guerrero completo. Nuestros entrenadores expertos te guiarán a través de cada movimiento, cada golpe, pero también te inspirarán a encontrar la valentía dentro de ti, a desafiar tus límites y a alcanzar nuevas alturas."}
                        fade={"fade-up"}
                    />
                </div>

                <div className="row pb-5">
                    <CardText
                        title={"Mucho más que una academia de boxeo."}
                        text1={"No importa si eres principiante o un veterano del ring, en Balam, todos somos una familia unida por la pasión por el boxeo. Nuestro compromiso es ayudarte a descubrir tu verdadero potencial, a esculpir tu cuerpo y mente con la tenacidad de un jaguar."}
                        text2={"Prepárate para sumergirte en un viaje transformador. En Balam, no solo aprenderás a boxear; aprenderás a conquistar. ¿Estás listo para liberar al jaguar que llevas dentro? ¡Únete a nosotros en la Academia de Box Balam y haz que cada golpe cuente!"}
                        fade={"fade-right"}
                    />
                    <CardImage
                        image={logo}
                        fade={"fade-left"}
                    />
                </div>


                <legend className="indexLegend" id="hrefBeneficios">Beneficios</legend>

                <div className="content-cards pb-5">
                    <article className="card card2 text-bg-dark">
                        <i className="bi bi-suit-heart-fill"></i>
                        <h3>Bueno para el corazón</h3>
                        <p>El boxeo es un ejercicio cardiovascular intenso que aumenta la frecuencia cardíaca y mejora la circulación sanguínea.</p>
                        <p>Favorece la salud cardiovascular al fortalecer el corazón y reducir el riesgo de enfermedades cardíacas.</p>
                    </article>

                    <article className="card card2 text-bg-dark">
                        <i className="bi bi-person-up"></i>
                        <h3>Mejora la condición física</h3>
                        <p>El entrenamiento en boxeo implica una combinación de resistencia, fuerza y ​​velocidad.</p>
                        <p>Ayuda a desarrollar la resistencia muscular y la coordinación mano-ojo, mejorando la forma física general.</p>
                        <p>Contribuye a la pérdida de peso y al mantenimiento de un peso saludable.</p>
                    </article>

                    <article className="card card2 text-bg-dark">
                        <i className="bi bi-emoji-smile"></i>
                        <h3>Salud mental</h3>
                        <p>El boxeo es un outlet efectivo para liberar el estrés y la tensión emocional.</p>
                        <p>Promueve la disciplina mental y la concentración al requerir enfoque durante el entrenamiento y la práctica.</p>
                        <p>Fomenta la autoconfianza y la autoestima al superar desafíos físicos y mentales.</p>
                    </article>
                </div>


                <div className="row pb-5">
                    <legend className="indexLegend" id="hrefUbicacion">Ubicación</legend>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2452.026148969971!2d-100.36833202474241!3d25.752090329259975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866296ba26d7e725%3A0xb5137230a9bfc62d!2sOc%C3%A9ano%20%C3%81rtico%208416%2C%20Loma%20Linda%2C%2064120%20Monterrey%2C%20N.L.!5e0!3m2!1ses-419!2smx!4v1700378392259!5m2!1ses-419!2smx"
                        width="600" height="450" className="mapStyle" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>


                <div className="row pb-5">
                    <legend className="indexLegend" id="hrefRedesSociales">Redes Sociales</legend>
                    <div className="content-cards pb-5">
                        <CardIcon
                            title={"Facebook"}
                            iconClass={"bi bi-facebook"}
                            link={"https://www.facebook.com/profile.php?id=100093518463216"}
                            fade={"fade-up"}
                            fadeDuration={"1000"}
                        />
                        <CardIcon
                            title={"Instagram"}
                            iconClass={"bi bi-instagram"}
                            link={"https://www.instagram.com/academia.balam.boox"}
                            fade={"fade-up"}
                            fadeDuration={"2000"}
                        />
                        <CardIcon
                            title={"Whatsapp"}
                            iconClass={"bi bi-whatsapp"}
                            link={"#"}
                            fade={"fade-up"}
                            fadeDuration={"3000"}
                        />
                    </div>
                </div>

            </div>
            
        </section>
        <Footer />
        </>
    );
}