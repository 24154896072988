import React from "react";

export const InputSelect = ({ value, onChange, title, idInput }) => {
    return (
        <label className="form-label">{title}
        <select className="form-select" aria-label="Default" id={idInput} value={value} onChange={onChange}>
            <option value="" selected>Selecciona una opción</option>

            <option value="1">Alumno</option>

        </select>
        </label>
    );
}