import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { InputUserName } from "../components/login/InputUserName";
import { InputPassword } from "../components/login/InputPassword";
import { ButtonLogin } from "../components/login/ButtonLogIn";
import { Footer } from "../components/Footer";
import api from '../api';

export function Login() {
    useEffect(() => {
        document.title = "Academia Balam Boox - Iniciar sesión";
    }, []);

    const navigate = useNavigate();

    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (navigator.onLine && localStorage.getItem('token')) {
            navigate("/Home", { replace: true });
        }
    });

    async function login() {
        try {
            const { data } = await api.post('/signin', {
                account,
                password,
            });

            if (!data) {
                alert('Cuenta o contraseña incorrectos');
                return;
            }

            localStorage.setItem('token', data.token)
            navigate("/Home");
        } catch (error) {
            console.log(error);
        }
    }

    function submit(event) {
        event.preventDefault();
        login();
    }

    return (
        <>
        <div className="container mt-5 mb-5">
            <div className="card text-bg-dark">
                <div className="card-body text-center">
                    <h3 className="card-title">Academia Balam Boox</h3>
                    <p className="card-text" style={{ fontSize: "1.5em" }}>Solo uso administrativo</p>
                    <form action="#" className="login-form" onSubmit={submit}>
                        <div className="row mb-3">
                            <InputUserName value={account} onChange={({ target }) => setAccount(target.value)} />
                        </div>
                        <div className="row mb-4">
                            <InputPassword value={password} onChange={({ target }) => setPassword(target.value)} />
                        </div>
                        <div className="row">
                            <ButtonLogin />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Footer />
        </>
    );
}