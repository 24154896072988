import { React, useEffect, useState } from "react";

import { ReturnButton } from "../components/customer/ReturnButton";
import { TableProgressDetails } from "../components/progress/TableProgressDetails";
import { useParams } from "react-router-dom";

export function ProgressDetails() {
    const params = useParams();
    const [idCustomer, setIdCustomer] = useState("");
    
    useEffect(() => {
        document.title = "Academia Balam Boox - Lista de alumnos";
        setIdCustomer(params.id);
    }, []);

    

    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-3">
                    <ReturnButton
                        link={"/progress-customer-list"}
                        icon={(
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        )}
                    />
                </div>
                <div className="col" />
            </div>

            <div className="card text-bg-dark">
                <div className="card-body">
                    <h3 className="card-title text-center">Progresos</h3>
                    <div className="row mb-4">
                        <TableProgressDetails 
                            id= {idCustomer}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
}