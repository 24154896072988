import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReturnButton } from "../components/customer/ReturnButton";
import { InputText } from "../components/customer/InputText";
import { SubmitEditCustomer } from "../components/customer/SubmitEditCustomer";
import { InputTextArea } from "../components/customer/InputTextArea";
import { InputFile } from "../components/customer/InputFile";
import { InputSelect } from "../components/customer/InputSelect";
import { InputDate } from "../components/customer/InputDate";
import { Toast } from "../components/toast/Toast";

export function EditCustomer() {
    useEffect(() => {
        document.title = "Academia Balam Boox - Editar alumno";
    }, []);

    const [toastText, setToastText] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [gender, setGender] = useState("");
    const [photo, setPhoto] = useState([]);
    const [notes, setNotes] = useState("");


    const navigate = useNavigate();

    async function submit(event) {
        event.preventDefault();

        setToastText("a");
        var x = document.getElementById("snackbar");
        // Add the "show" class to DIV
        x.className = "show";
        // After 3 seconds, remove the show class from DIV
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

        //navigate("/Home");
    }

    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-3">
                    <ReturnButton
                        link={"/edit-customer-list"}
                        icon={(
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        )}
                    />
                </div>
                <div className="col" />
            </div>

            <div className="card text-bg-dark">
                <div className="card-body">
                    <h3 className="card-title text-center">Editar alumno</h3>

                    <form action="#" className="login-form" onSubmit={submit}>
                        <div className="row mb-3">
                            <InputText
                                value={firstName}
                                onChange={(e) => { setFirstName(e.target.value); }}
                                title={"Nombre(s)"}
                                idInput={"inputFirstName"}
                                placeholderText={"Nombre(s) del alumno"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputText
                                value={lastName}
                                onChange={(e) => { setLastName(e.target.value); }}
                                title={"Apellido(s)"}
                                idInput={"inputLastName"}
                                placeholderText={"Apellidos(s) del alumno"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputDate
                                value={birthDate}
                                onChange={(e) => { setBirthDate(e.target.value); }}
                                title={"Fecha de nacimiento"}
                                idInput={"inputBirthDate"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputSelect
                                value={gender}
                                onChange={(e) => { setGender(e.target.value); }}
                                title={"Genero"}
                                idInput={"inputGender"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputFile
                                value={photo}
                                onChange={(e) => { setPhoto(e.target.value); }}
                                title={"Foto"}
                                idInput={"inputPhoto"}
                            />
                        </div>

                        <div className="row mb-4">
                            <InputTextArea
                                value={notes}
                                onChange={(e) => { setNotes(e.target.value); }}
                                title={"Notas"}
                                idInput={"inputNotes"}
                                numRows={3}
                                placeholderText={"Anotaciones extras"}
                            />
                        </div>

                        <div className="row">
                            <SubmitEditCustomer
                                title={"Editar"}
                                idButton={"SubmitButton"}
                            />
                        </div>
                    </form>

                </div>
            </div>

            <div className="row justify-content-center">
                <Toast
                    text={toastText}
                />
            </div>
        </div>
    );
}