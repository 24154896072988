import React from "react";
import { Link } from "react-router-dom";
import logo from "../resources/logo.png";

export const IndexHeader = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
            <div className="container-fluid container">
                <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <img src={logo} alt="logo" width="30" height="24" className="d-inline-block align-text-top" />
                    <span className="fs-4 navbar-brand">Balam Boox</span>
                </a>

                <div className="collapse navbar-collapse justify-content-center" id="navbarTogglerDemo01">
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" href="#hrefBeneficios">Beneficios</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="#hrefUbicacion">Ubicación</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#hrefRedesSociales">Redes sociales</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Foro</a>
                        </li>
                    </ul>
                </div>
                <Link to={"/login"} className="btn btn-outline-light me-2" href="/login">Iniciar sesión</Link>
                <div className="text-end">
                    
                </div>
            </div>
        </nav>
    );
}