import React from "react";
import { ItemMembers } from "./ItemMembers";

export const TableMembers = ({ }) => {
    return (
        <table className="table table-striped">
            <thead>
                <tr className="text-center">
                    <th scope="col">Nombre</th>
                    <th scope="col-1">Fecha fin</th>
                    <th scope="col-1"><i className="bi bi-star"></i></th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                <ItemMembers
                    id={1}
                    name={"nombre"}
                    endDate={"11/11"}
                    isMember={true}
                />

                <ItemMembers
                    id={2}
                    name={"nombre2"}
                    endDate={"11/11"}
                    isMember={false}
                />
            </tbody>

        </table>
    );
}