import { React, useEffect, useState } from "react";

import { ReturnButton } from "../components/customer/ReturnButton";
import { TableDeleteCustomer } from "../components/customer/TableDeleteCustomer";
import { Toast } from "../components/toast/Toast";


export function DeleteCustomerList() {
    useEffect(() => {
        document.title = "Academia Balam Boox - Lista de alumnos";
    }, []);

    const [toastText, setToastText] = useState("");

    async function activeCustomer(isDeleting, id) {
        //
        
        if (isDeleting) {
            setToastText("Dado de baja");
        } else {
            setToastText("Activado");
        }
        showToast();
    }

    function showToast() {
        var x = document.getElementById("snackbar");
        // Add the "show" class to DIV
        x.className = "show";
        // After 3 seconds, remove the show class from DIV
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    }

    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-3">
                    <ReturnButton
                        link={"/customers-home"}
                        icon={(
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        )}
                    />
                </div>
                <div className="col" />
            </div>

            <div className="card text-bg-dark">
                <div className="card-body">
                    <h3 className="card-title text-center">Lista de alumnos <i className="bi bi-person-fill-x"></i></h3>
                    <TableDeleteCustomer
                        onClick={(a,b) => { activeCustomer(a,b); }}
                    />
                </div>
            </div>

            <div className="row justify-content-center">
                <Toast
                    text={toastText}
                />
            </div>
        </div>
    );
}