import { React, useEffect, useState } from "react";

import { ReturnButton } from "../components/customer/ReturnButton";
import { TableCustomer } from "../components/customer/TableCustomer";

export function CustomerDetails() {
    useEffect(() => {
        document.title = "Academia Balam Boox - Lista de alumnos";
    }, []);

    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-3">
                    <ReturnButton
                        link={"/customer-list"}
                        icon={(
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        )}
                    />
                </div>
                <div className="col" />
            </div>

            <div className="card text-bg-dark">
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col">
                            <h3 className="card-title">nombre sdsdfsf dadakljdajkd ads dskjsfksjsk</h3>
                        </div>
                        <div className="col">
                            <img style={{maxHeight: "10em"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Shiba_inu_taiki.jpg/320px-Shiba_inu_taiki.jpg" class="rounded mx-auto d-block" alt="..." />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p style={{fontWeight: "bold"}}>Edad:</p>
                        </div>
                        <div className="col">
                            <p>si</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <p style={{fontWeight: "bold"}}>Genero:</p>
                        </div>
                        <div className="col">
                            <p>si</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <p style={{fontWeight: "bold"}}>Peso:</p>
                        </div>
                        <div className="col">
                            <p>si</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <p style={{fontWeight: "bold"}}>Altura:</p>
                        </div>
                        <div className="col">
                            <p>si</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <p style={{fontWeight: "bold"}}>Condición:</p>
                        </div>
                        <div className="col">
                            <p>si</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <p style={{fontWeight: "bold"}}>Anotaciones:</p>
                        </div>
                        <div className="col">
                            <p>si</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <p style={{fontWeight: "bold"}}>Status</p>
                        </div>
                        <div className="col">
                            <p>si</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}