import { React, useEffect, useState } from "react";
import { HomeFooter } from "../components/HomeFooter";
import { InputText } from "../components/settings/InputText";
import { Toast } from "../components/toast/Toast";
import { SubmitAcountSettings } from "../components/settings/SubmitAcountSettings";
import { InputPassword } from "../components/settings/InputPassword";
import { ReturnButton } from "../components/customer/ReturnButton";

export function AcountSettings() {

    useEffect(() => {
        document.title = "Academia Balam Boox - Configurar cuenta";
    }, []);


    const [toastText, setToastText] = useState("");

    const [name, setName] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [username, setUsername] = useState("");
    const [Password, setPassword] = useState("");

    async function submit(event) {
        event.preventDefault();

        setToastText("a");

        var x = document.getElementById("snackbar");
        // Add the "show" class to DIV
        x.className = "show";
        // After 3 seconds, remove the show class from DIV
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

        //navigate("/Home");
    }

    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-3">
                    <ReturnButton
                        link={"/settings"}
                        icon={(
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        )}
                    />
                </div>
                <div className="col" />
            </div>

            <div className="card text-bg-dark">
                <div className="card-body">
                    <h3 className="card-title text-center">Configurar cuenta <i className="bi bi-person-fill-exclamation"></i></h3>
                    <form action="#" className="login-form" onSubmit={submit}>
                        <div className="row mb-3">
                            <InputText
                                value={name}
                                onChange={(e) => { setName(e.target.value); }}
                                title={"Nombre"}
                                idInput={"inputName"}
                                placeholderText={"Nombre del titular"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputText
                                value={phoneNum}
                                onChange={(e) => { setPhoneNum(e.target.value); }}
                                title={"Teléfono"}
                                idInput={"inputPhoneNum"}
                                placeholderText={"Número telefónico de contacto"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputText
                                value={username}
                                onChange={(e) => { setUsername(e.target.value); }}
                                title={"Usuario"}
                                idInput={"inputUsername"}
                                placeholderText={"Nombre de usuario"}
                            />
                        </div>

                        <div className="row mb-4">
                            <InputPassword
                                value={Password}
                                onChange={(e) => { setPassword(e.target.value); }}
                                title={"Password"}
                                idInput={"inputPassword"}
                                placeholderText={"Llena solo si quieres cambiar contraseña"}
                            />
                        </div>

                        <div className="row">
                            <SubmitAcountSettings
                                title={"Actualizar"}
                                idButton={"SubmitButton"}
                            />
                        </div>

                    </form>
                </div>
                <div className="row justify-content-center">
                    <Toast
                        text={toastText}
                    />
                </div>
            </div>
        </div>

    );
}