import React from "react";

export const Banner = ({ value, onClick }) => {
    return (
        <header className="banner">
            <div className="banner-text">
                <h1>Academia Balam Boox</h1>
                <legend>•Estilo 2023•</legend>
            </div>
        </header>
    );
}