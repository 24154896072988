import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { InputUserName } from "../components/login/InputUserName";
import { InputPassword } from "../components/login/InputPassword";
import { ButtonLogin } from "../components/login/ButtonLogIn";
import { HomeFooter } from "../components/HomeFooter";
import { InputText } from "../components/customer/InputText";
import { SubmitCreateCustomer } from "../components/customer/SubmitCreateCustomer";
import { ReturnButton } from "../components/customer/ReturnButton";
import { InputTextArea } from "../components/customer/InputTextArea";
import { InputFile } from "../components/customer/InputFile";
import { InputSelect } from "../components/customer/InputSelect";
import { InputDate } from "../components/customer/InputDate";

export function CreateCustomer() {
    useEffect(() => {
        document.title = "Academia Balam Boox - Agregar alumno";
    }, []);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [gender, setGender] = useState("");
    const [photo, setPhoto] = useState([]);
    const [notes, setNotes] = useState("");


    const navigate = useNavigate();

    async function submit(event) {
        event.preventDefault();

        navigate("/Response/200/si jala");
    }

    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-3">
                    <ReturnButton
                        link={"/customers-home"}
                        icon={(
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        )}
                    />
                </div>
                <div className="col" />
            </div>

            <div className="card text-bg-dark">
                <div className="card-body text-center">
                    <h3 className="card-title">Agregar alumno <i class="bi bi-person-fill-add"></i></h3>
                    <form action="#" className="login-form" onSubmit={submit}>
                        <div className="row mb-3">
                            <InputText
                                value={firstName}
                                onChange={(e) => { setFirstName(e.target.value); }}
                                title={"Nombre(s)"}
                                idInput={"inputFirstName"}
                                placeholderText={"Nombre(s) del alumno"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputText
                                value={lastName}
                                onChange={(e) => { setLastName(e.target.value); }}
                                title={"Apellido(s)"}
                                idInput={"inputLastName"}
                                placeholderText={"Apellidos(s) del alumno"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputDate
                                value={birthDate}
                                onChange={(e) => { setBirthDate(e.target.value); }}
                                title={"Fecha de nacimiento"}
                                idInput={"inputBirthDate"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputSelect
                                value={gender}
                                onChange={(e) => { setGender(e.target.value); }}
                                title={"Genero"}
                                idInput={"inputGender"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputFile
                                value={photo}
                                onChange={(e) => { setPhoto(e.target.value); }}
                                title={"Foto"}
                                idInput={"inputPhoto"}
                            />
                        </div>

                        <div className="row mb-4">
                            <InputTextArea
                                value={notes}
                                onChange={(e) => { setNotes(e.target.value); }}
                                title={"Notas"}
                                idInput={"inputNotes"}
                                numRows={3}
                                placeholderText={"Anotaciones extras"}
                            />
                        </div>

                        <div className="row">
                            <SubmitCreateCustomer
                                title={"Agregar"}
                                idButton={"SubmitButton"}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}