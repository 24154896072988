import React from "react";
import { Link } from "react-router-dom";

export const ItemEditCustomer = ({ id, name }) => {
    return (
        <tr>
            <td>{name}</td>
            <td className="text-center"><Link to={"/edit-customer/" + id}><i className="bi bi-gear-fill"></i></Link></td>
        </tr>
    );
}