import React from "react";

export const ItemMembers = ({ id, name, endDate, isMember }) => {
    if(isMember){
        return (
            <tr>
                <td>{name}</td>
                <td className="text-center">{endDate}</td>
                <td className="text-center"><i className="bi bi-star-fill" /></td>
            </tr>
        );
    }
    else{
        return (
            <tr>
                <td>{name}</td>
                <td className="text-center">{endDate}</td>
                <td className="text-center"></td>
            </tr>
        );
    }
    

}