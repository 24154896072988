import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Toast } from "../components/toast/Toast";
import { InputNum } from "../components/progress/InputNum";
import { SubmitEditProgress } from "../components/progress/SubmitEditProgress";
import { InputDate } from "../components/progress/InputDate";
import { InputText } from "../components/progress/InputText";
import { InputTextArea } from "../components/progress/InputTextArea";
import { ReturnButton } from "../components/customer/ReturnButton";

export function EditProgress() {
    useEffect(() => {
        document.title = "Academia Balam Boox - Editar progreso";
    }, []);

    const [toastText, setToastText] = useState("");
    const [date, setDate] = useState("");
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [condition, setCondition] = useState("");
    const [annotations, seAnnotations] = useState("");

    const navigate = useNavigate();

    async function submit(event) {
        event.preventDefault();

        setToastText("a");

        var x = document.getElementById("snackbar");
        // Add the "show" class to DIV
        x.className = "show";
        // After 3 seconds, remove the show class from DIV
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

        //navigate("/Home");
    }

    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-3">
                    <ReturnButton
                        link={"/edit-progress-list"}
                        icon={(
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
                            </svg>
                        )}
                    />
                </div>
                <div className="col" />
            </div>

            <div className="card text-bg-dark">
                <div className="card-body">
                    <h3 className="card-title text-center">Editar progreso</h3>

                    <form action="#" className="login-form" onSubmit={submit}>
                        <div className="row mb-3">
                            <InputDate
                                value={date}
                                onChange={(e) => { setDate(e.target.value); }}
                                title={"Mes"}
                                idInput={"inputDate"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputNum
                                value={weight}
                                onChange={(e) => { setWeight(e.target.value); }}
                                title={"Peso"}
                                idInput={"inputWeight"}
                                placeholderText={"Actualización de peso"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputNum
                                value={height}
                                onChange={(e) => { setHeight(e.target.value); }}
                                title={"Altura"}
                                idInput={"inputHeight"}
                                placeholderText={"Actualización de altura"}
                            />
                        </div>

                        <div className="row mb-3">
                            <InputText
                                value={condition}
                                onChange={(e) => { setCondition(e.target.value); }}
                                title={"Condición"}
                                idInput={"inputCondition"}
                                placeholderText={"Actualizar condición"}
                            />
                        </div>

                        <div className="row mb-4">
                            <InputTextArea
                                value={annotations}
                                onChange={(e) => { seAnnotations(e.target.value); }}
                                title={"Anotaciones"}
                                idInput={"inputAnnotations"}
                                placeholderText={"Anotaciones extras"}
                            />
                        </div>

                        <div className="row">
                            <SubmitEditProgress
                                title={"Agregar"}
                                idButton={"SubmitButton"}
                            />
                        </div>
                    </form>

                </div>
            </div>

            <div className="row justify-content-center">
                <Toast
                    text={toastText}
                />
            </div>
        </div>
    );
}