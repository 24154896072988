import { compareDates } from "./Validations/compareDates";
import { validateSelection } from "./Validations/validateSelection";

export async function validateAddMembership() {
    let elementIdCustomer = document.getElementById('inputIdCustomer');
    let elementStartDate = document.getElementById('inputStartDate');
    let elementEndDate = document.getElementById('inputEndDate');
    
    elementIdCustomer.setCustomValidity(validateSelection(elementIdCustomer.value));
    elementEndDate.setCustomValidity(compareDates(elementStartDate.value, elementEndDate.value));

}