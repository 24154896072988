import React from "react";

export const Footer = () => {
    return (
        <footer className="footer py-3 navbar-dark bg-dark mt-auto">
            <div className="container">
                <ul class="nav justify-content-center border-bottom pb-3 mb-3">
                    <li className="nav-item"><a target="_blank" href="./" class="nav-link px-2 text-muted"><i className="bi bi-house-fill"></i>Inicio</a></li>
                    <li className="nav-item"><a target="_blank" href="https://www.google.com/maps?ll=25.751371,-100.366433&z=17&t=m&hl=es-419&gl=MX&mapclient=embed&q=Oc%C3%A9ano+%C3%81rtico+8416+Loma+Linda+64120+Monterrey,+N.L." class="nav-link px-2 text-muted"><i class="bi bi-pin-map-fill"></i>Maps</a></li>
                    <li className="nav-item"><a target="_blank" href="https://www.facebook.com/profile.php?id=100093518463216" className="nav-link px-2 text-muted"><i className="bi bi-facebook"></i>Facebook</a></li>
                    <li className="nav-item"><a target="_blank" href="https://www.instagram.com/academia.balam.boox" className="nav-link px-2 text-muted"><i class="bi bi-instagram"></i>Instagram</a></li>
                    <li className="nav-item"><a target="_blank" href="#" class="nav-link px-2 text-muted"><i className="bi bi-whatsapp"></i>WhatsApp</a></li>
                    <li className="nav-item"><a href="#" class="nav-link px-2 text-muted"><i class="bi bi-wechat"></i>Foro</a></li>
                </ul>
                <p className="text-center text-muted">&copy; 2023 LMAD Company, Inc</p>
            </div>
        </footer>

    );
}