import React from "react";
import { ItemCustomer } from "./ItemCustomer";

export const TableCustomer = ({ }) => {
    return (
        <table className="table table-striped">
            <thead>
                <tr className="text-center">
                    <th scope="col">Nombre</th>
                    <th scope="col-1"><i className="bi bi-file-person"></i></th>
                </tr>
            </thead>
            <tbody class="table-group-divider">
                <ItemCustomer
                    id={1}
                    name={"nombre"}
                />

                <ItemCustomer
                    id={2}
                    name={"nombre2"}
                />
            </tbody>

        </table>
    );
}