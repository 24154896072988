import { React, useEffect, useState } from "react";
import { HomeFooter } from "../components/HomeFooter";

export function AppInfo() {

    useEffect(() => {
        document.title = "Academia Balam Boox - Información";
    }, []);


    return (
        <>
            <div className="container mt-5 mb-5">
                <div className="card text-bg-dark">
                    <div className="card-body">
                        <h3 className="card-title text-center">Información</h3>
                        <div className="row mb-4">
                            <div className="row">
                                <div className="col">
                                    <p style={{ fontWeight: "bold" }}>Versión:</p>
                                </div>
                                <div className="col">
                                    <p>1.0.0</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <p style={{ fontWeight: "bold" }}>App:</p>
                                </div>
                                <div className="col">
                                    <p>Gestión de información de alumnos, seguimiento de progreso mensual y uso de membresías.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <HomeFooter />
        </>
    );
}