import React from "react";

export const CardImage = ({ image, fade }) => {
    return (

        <div className="col-sm-4 mb-3 mb-sm-0" data-aos={fade}>
            <div className="card text-bg-dark">
                <img src={image} alt="..." style={{maxHeight: "100 px"}} />
            </div>
        </div>

    );
}