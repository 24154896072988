import React from "react";

export const CardIcon = ({ title, iconClass, link, fade, fadeDuration }) => {
    return (
        <article className="card card2 text-bg-dark" data-aos={fade} data-aos-duration={fadeDuration}>
            <a className="nav-link link-light" href={link} target="_blank">
                <div className="card-body">
                    <h3 className="card-title">{title}</h3>
                    <p><i style={{ color: "white" }} className={iconClass}></i></p>
                </div>
            </a>
        </article>
    );
}