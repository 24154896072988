import React from "react";
import { Link } from "react-router-dom";

export const ItemProgresses = ({ id, name }) => {
    return (
        <tr>
            <td>{name}</td>
            <td className="text-center"><Link to={"/details-progress/" + id}><i className="bi bi-clipboard2-pulse-fill" /></Link></td>
        </tr>
    );
}