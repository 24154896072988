import { validateName } from "./Validations/validateName";
import { validateBirthdate } from "./Validations/validateBirthdate";
import { validateImage } from "./Validations/validateImage";
import { validateSelection } from "./Validations/validateSelection";

export async function validateEditCustomer() {
    let elementFirstName = document.getElementById('inputFirstName');
    let elementLastName = document.getElementById('inputLastName');
    let elementBirthDate = document.getElementById('inputBirthDate');
    let elementGender = document.getElementById('inputGender');
    let elementPhoto = document.getElementById('inputPhoto');
    let elementNotes = document.getElementById('inputNotes');

    elementFirstName.setCustomValidity(validateName(elementFirstName.value));
    elementLastName.setCustomValidity(validateName(elementLastName.value));
    elementBirthDate.setCustomValidity(validateBirthdate(elementBirthDate.value));
    elementGender.setCustomValidity(validateSelection(elementGender.value));
    elementPhoto.setCustomValidity(validateImage(elementPhoto.value));
}