import { React, useEffect } from "react";
import { ButtonLink } from "../components/home/ButtonLink";
import { HomeFooter } from "../components/HomeFooter";
import { useParams } from "react-router-dom";
import { SetResponse } from "../components/response/SetResponse";

export function Response() {
    const params = useParams();

    useEffect(() => {
        document.title = "Academia Balam Boox";
    }, []);

    return (
        <>
            <div className="container mt-5 mb-5">
                <div className="card text-bg-dark">
                    <div className="card-body text-center">
                        <h3 className="card-title">Academia Balam Boox</h3>
                        <SetResponse
                            status={params.status}
                            response={params.response}
                        />
                    </div>
                </div>
            </div>
            <HomeFooter />
        </>
    );
}